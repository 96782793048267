import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SamplesItem from './samplesItem';
import SamplesMain from './samplesMainCollage';
import SamplesWriting from './samplesWriting';

interface SamplesMetadata {
  id: number;
  projecttitle: string;
  imgalt: string;
  isyoutubevideo: boolean;
  isspotify: boolean;
  url: string;
  projectimg: {
    imgix_url: string;
  };
  type: {
    key: string;
  };
}

interface SamplesEntry {
  node: {
    metadata: SamplesMetadata;
  };
}

interface QueryResult {
  allCosmicjsSamples: {
    edges: SamplesEntry[];
  };
}

const SamplesComponent = () => {
  // GraphQL query to fetch data from Cosmic JS
  const samplesQueryData = useStaticQuery<QueryResult>(graphql`
    query {
      allCosmicjsSamples(sort: { fields: [metadata___id], order: ASC }) {
        edges {
          node {
            metadata {
              id
              projecttitle
              imgalt
              isyoutubevideo
              isspotify
              url
              projectimg {
                imgix_url
              }
              type {
                key
              }
            }
          }
        }
      }
    }
  `);

  const samplesData = samplesQueryData.allCosmicjsSamples.edges.map(
    (edge: SamplesEntry) => edge.node.metadata
  );

  let mainSample: SamplesMetadata | any = null;
  const mediaSamples: SamplesMetadata[] = [];
  let writingSample: SamplesMetadata | any = null;

  samplesData.forEach((sample) => {
    if (sample.type.key === 'mainSample') {
      mainSample = sample;
    } else if (sample.type.key === 'mediaSample') {
      mediaSamples.push(sample);
    } else if (sample.type.key === 'writingSample') {
      writingSample = sample;
    }
  });

  return (
    <div className="PortfolioComponent">
      {/* Page Title */}
      <div className="title-section text-left text-sm-center">
        <h1>
          Sample <span className="main-color">Work</span>
        </h1>
      </div>
      <div className="container">
        <div className="row text-center">
          <div className="col-12 col-lg-6">
            {mainSample && (
              <SamplesMain
                projectTitle={mainSample.projecttitle}
                projectImg={mainSample.projectimg.imgix_url}
                imgAlt={mainSample.imgalt}
                isYoutubeVideo={mainSample.isyoutubevideo}
                isSpotify={mainSample.isspotify}
                url={mainSample.url}
              />
            )}
          </div>
          <div className="col-12 col-lg-6">
            <div className="row row-cols-1 row-cols-lg-2">
              {mediaSamples
                .filter((sample: SamplesMetadata) => sample.id <= 4)
                .map((sample: SamplesMetadata) => (
                  <div className="col" key={sample.id}>
                    <SamplesItem
                      projectTitle={sample.projecttitle}
                      projectImg={sample.projectimg.imgix_url}
                      imgAlt={sample.imgalt}
                      isYoutubeVideo={sample.isyoutubevideo}
                      isSpotify={sample.isspotify}
                      url={sample.url}
                    />
                  </div>
                ))}
            </div>
          </div>
          {mediaSamples
            .filter((sample: SamplesMetadata) => sample.id > 4)
            .map((sample: SamplesMetadata) => (
              <div className="col-12 col-lg-4" key={sample.id}>
                <SamplesItem
                  projectTitle={sample.projecttitle}
                  projectImg={sample.projectimg.imgix_url}
                  imgAlt={sample.imgalt}
                  isYoutubeVideo={sample.isyoutubevideo}
                  isSpotify={sample.isspotify}
                  url={sample.url}
                />
              </div>
            ))}
          <div className="col-12 col-lg-4">
            {writingSample && (
              <SamplesWriting
                imgSrc={writingSample.projectimg.imgix_url}
                articleLink={writingSample.url}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SamplesComponent;
