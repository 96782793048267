import React from 'react';

interface SpotifyUrl {
	url: string;
}

const SpotifyPlayer: React.FC<SpotifyUrl> = ({ url }) => {
	return (
		<>
			<iframe
				src={url}
				width="100%"
				height="330"
				frameBorder="0"
				allowTransparency={true}
				title="Sixers Coat Check Podcast"
			></iframe>
		</>
	);
};

export default SpotifyPlayer;
